/* global switchCtrl */
/* global filePreviewCtrl */

// Mobile sidebor
$('[data-toggle-sidebar]').each(sidebarCtrl);

function sidebarCtrl() {
  var $el = $(this);

  // Variables
  var $sidebar = $('[data-sidebar]');
  var $sideContent = $('[data-side-content]');

  // Events
  activate();
  $el.on('click', toggleSidebar);

  // ////////////////////////////////////////

  function activate() {
    var page = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    console.log('page = ' + page);
    $('[data-page-' + page + ']').addClass('active');
    $('.bo-event__select').change(toggleDigitalLocal);
    toggleDigitalLocal();
  }

  function toggleDigitalLocal() {
    const val = $('.bo-event__select').val();
    if (val === 'digital') {
      $('.venue-info').css('display', 'none');
    } else {
      $('.venue-info').css('display', 'flex');
    }
  }

  function toggleSidebar() {
    if ($el.hasClass('open')) {
      $el.removeClass('open');
      $sidebar.removeClass('open');
      $sideContent.removeClass('open');
    } else {
      $el.addClass('open');
      $sidebar.addClass('open');
      $sideContent.addClass('open');
    }
  }
}

// Blocks - used for Challenges/Prizes/Resources/Partners
$('[data-boblock]').each(blocksCtrl);

function blocksCtrl() {
  var $el = $(this);

  // Variables
  var $blockAdd = $('[data-boblock-add]');
  var $blockTemplate = $('[data-boblock-template]').html();

  // Events
  $blockAdd.on('click', addNewBlock);

  // ////////////////////////////////////////

  function addNewBlock() {
    $blockAdd.before($blockTemplate);
    $('[data-switch]').eq(-2).switchCtrl();
    $('[data-boblock-item]').each(blockCtrl);
    $('[data-pf]').each(function() {
      $(this).filePreviewCtrl();
    });
    setBlockIndex();
  }
}

$('[data-boblock-item]').each(blockCtrl);

function blockCtrl() {
  var $el = $(this);

  // Variables
  var $blockDelete = $('[data-boblock-delete]');

  // Events
  $blockDelete.on('click', deleteBlock);

  // ////////////////////////////////////////

  function deleteBlock() {
    $(this).parents('.column').remove();
    setBlockIndex();
  }
}

function setBlockIndex() {
  var blocks = $('[data-boblock-item]');
  var nbBlocks = $('[data-boblock-item]').length - 1;

  // Update Challenge number
  for (var i = 0; i < nbBlocks; i++) {
    $('.bo-event-challenge__number', blocks[i]).text(i + 1);
  }

  // Update name attribute index
  for (var j = 0; j < nbBlocks; j++) {
    $('[name]', blocks[j]).each(function() {
      var tmp = $(this).attr('name').replace(/\d+/g, '');
      $(this).attr('name', tmp + j);

      var elemType = $(this).attr('type');
      if (elemType === 'file') {
        $(this).attr('id', tmp + j);
      }
    });
    $('[for]', blocks[j]).each(function() {
      var tmp = $(this).attr('for').replace(/\d+/g, '');
      $(this).attr('for', tmp + j);
    });
  }
}
