/* global forcedRedirectionUrl */

$('[name=telephone]').intlTelInput({
  numberType: 'MOBILE',
  nationalMode: false
});

$('[data-register-1]').on('submit', function (event) {
  $('[data-register-error]').html('');
  $('[data-register-error-privacy-policies]').html('');
  $('[data-register-1] [name=privacyPolicies]').removeClass('error');
  event.preventDefault();

  if (global.forcedRedirectionUrl) {
    $(this).find('[name=redirectionUrl]').val(global.forcedRedirectionUrl);
  }

  if (Intl && Intl.DateTimeFormat() &&
    Intl.DateTimeFormat().resolvedOptions() &&
    Intl.DateTimeFormat().resolvedOptions().timeZone) {
    $(this).find('[name=timezone]').val(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
  $.post($(this).attr('action'), $(this).serialize())
    .success(function (res) {
      location.href = res.redirectTo;
    })
    .error(function (res) {
      if (res.responseJSON.code === 'privacy_policies_required') {
        $('[data-register-error-privacy-policies]').html(res.responseJSON.message);
        $('[data-register-1] [name=privacyPolicies]').addClass('error');
      } else {
        $('[data-register-error]').html(res.responseJSON.message);
      }
      $('[data-button-loader]').removeClass('active');
    });
});

$('[data-register-2]').on('submit', function (event) {
  $('[data-register-error]').html('');
  $('[data-register-error-privacy-policies]').html('');
  $('[data-register-1] [name=privacyPolicies]').removeClass('error');
  event.preventDefault();

  $.post($(this).attr('action'), $(this).serialize())
    .success(function (res) {
      location.href = res.redirectTo;
    })
    .error(function (res) {
      if (res.responseJSON.code === 'privacy_policies_required') {
        $('[data-register-error-privacy-policies]').html(res.responseJSON.message);
        $('[data-register-2] [name=privacyPolicies]').addClass('error');
      } else {
        $('[data-register-error]').html(res.responseJSON.message);
      }
      $('[data-button-loader]').removeClass('active');
    });
});

// Prevent button loader if required field aren't completed
$('[data-register-1] [data-button-loader]').on('click', function () {
  if (
    !$('[data-register-1] [name=username]').val() ||
    !$('[data-register-1] [name=email]').val() ||
    !$('[data-register-1] [name=password]').val() ||
    !$('[data-register-1] [name=city]').val() ||
    $('[data-register-1] [name=privacyPolicies]').val() === 'off' ||
    !document.getElementById('email').validity.valid
  ) {
    setTimeout(function () {
      $('[data-register-1] [data-button-loader]').removeClass('active');
    }, 100);
  }
});
$('[data-register-2] [data-button-loader]').on('click', function () {
  if (!$('[data-register-2] [name=username]').val() || !$('[data-register-2] [name=email]').val() ||
    !$('[data-register-2] [name=password]').val() || !$('[data-register-2] [name=city]').val()
    || $('[data-register-1] [name=privacyPolicies]').val() === 'off') {
    setTimeout(function () {
      $('[data-register-2] [data-button-loader]').removeClass('active');
    }, 100);
  }
});
