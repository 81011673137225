$('[data-m-nav]').each(mobileNavCtrl);

function mobileNavCtrl() {
  var $el = $(this);

  // Variables
  var $body = $('body');
  var $submenu = $('[data-m-submenu]');
  var $submenuCloser = $('[data-m-closer]');

  // Events
  $el.on('click', showSubMenu);
  $submenuCloser.on('click', hideSubmenu);

  // ////////////////////////////////////////

  function showSubMenu(e) {
    e.preventDefault();
    $body.addClass('ovh');
    $submenu.hide();
    $(this).next($submenu).show();
  }

  function hideSubmenu(e) {
    e.preventDefault();
    $el.next($submenu).hide();
    $body.removeClass('ovh');
  }
}
