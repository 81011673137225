$(function() {
  $('body').on('click', '[data-index]', function() {
    var currentIndex = $(this).data('index');

    $('.event-prize__featured-prize').removeClass('active');
    $('.event-prize__featured-prize[data-index=' + currentIndex + ']').addClass('active');

    $('.event-prize__item').removeClass('selected');
    $('.event-prize__item[data-index=' + currentIndex + ']').addClass('selected');
  });
});

// Admin tools
$('[data-admin-tools]').each(adminToolsCtrl);

function adminToolsCtrl() {
  var $el = $(this);

  // Variables
  var $toggle = $('[data-toggle-tools]', $el);
  var savedState = window.localStorage.getItem('eventPageShowTools');
  console.log('savedState = ' + savedState);

  // Events
  activate();
  $toggle.on('click', toggleTools);

  // ////////////////////////////////////////

  function activate() {
    if (savedState === null) {
      toggleTools(true);
      window.localStorage.setItem('eventPageShowTools', 'true');
    } else {
      toggleTools(savedState);
    }
  }

  function toggleTools(state) {
    if ($toggle.hasClass('open') || state === 'false') {
      $el.removeClass('open');
      $toggle.removeClass('open');
      window.localStorage.setItem('eventPageShowTools', 'false');
    } else {
      $el.addClass('open');
      $toggle.addClass('open');
      window.localStorage.setItem('eventPageShowTools', 'true');
    }
  }
}
