
require('jquery');

require('../theme/scss/hackathon.scss');

// Common

require('../app/modules/hackathon/views/common/add-on/a-moment');
require('../app/modules/hackathon/views/common/add-on/bootstrap-material-datepicker');
require('../app/modules/hackathon/views/common/add-on/jquery-countdown');
require('../app/modules/hackathon/views/common/components/confirm-email/confirm-email');
require('../app/modules/hackathon/views/common/components/file-preview/file-preview');
require('../app/modules/hackathon/views/common/components/flash-msg/flash-msg');
require('../app/modules/hackathon/views/common/components/switch/switch');
require('../app/modules/hackathon/views/common/components/textarea-counter/textarea-counter');
require('../app/modules/hackathon/views/common/components/topics/topics');
require('../app/modules/hackathon/views/common/components/button-loader');
require('../app/modules/hackathon/views/common/components/footer');
require('../app/modules/hackathon/views/common/components/mobile-nav');
require('../app/modules/hackathon/views/common/components/scroll-to');
require('../app/modules/hackathon/views/common/components/timer');
require('../app/modules/hackathon/views/common/pagination-more');

// Creation

require('../app/modules/hackathon/views/creation/creation');

// Event

require('../app/modules/hackathon/views/event/event');
require('../app/modules/hackathon/views/event/event.edit');

// Home

require('../app/modules/hackathon/views/home/home');

// Profile

require('../app/modules/hackathon/views/profile/profile');

// Register

require('../app/modules/hackathon/views/register/validation.mail');

// Search

require('../app/modules/hackathon/views/search/search');

// Connect$

require('../app/modules/connect/views/sign-popup');
require('../app/modules/connect/views/forgot/forgot');
require('../app/modules/connect/views/forgot/forgot.mail');
require('../app/modules/connect/views/forgot/reset');
require('../app/modules/connect/views/login/login');
require('../app/modules/connect/views/profile/profile');
require('../app/modules/connect/views/register/confirmationHackathon.mail');
require('../app/modules/connect/views/register/register');
