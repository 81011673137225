$('[data-textarea-counter]').each(textareaCounterCtrl);

function textareaCounterCtrl() {
  const $textarea = $('[data-textarea-counter-text]', this);
  const $counter = $('[data-textarea-counter-label]', this);
  const maxLength = $textarea.attr('maxlength');

  setRemainingCharacter();

  $textarea
    .on('keyup', setRemainingCharacter);

  function setRemainingCharacter() {
    $counter.text(maxLength - $textarea.val().length);
  }
}
