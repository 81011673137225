/*
Usage:
Sur le lien de chargement ajouter: data-pagination-more-link="latest"
Sur la liste (le <ul> par exemple): data-pagination-more-list="latest"
latest est l'identifiant unique dans la page

Dans le DOM le lien peut être partout sauf dans la liste.
La liste peut être un ul ou un div, du moment que les enfants sont les éléments.
Le lien doit avoir le lien vers la page suivante (href).

Exemple:
<ul data-pagination-more-link="latest">
  <li>1</li>
  <li>2</li>
</ul>
<a href="http://.../$skip=XXX" data-pagination-more-list="latest"></a>
*/

$(document).on('click', '[data-pagination-more-link]', onClick);

var disabledLinks = {}; // avoid double-click = double-loading

// ////////////////////////////////////////////////////////////////////////////

function onClick(e) {
  e.preventDefault();

  // Les éléments de la page actuelle
  var $link = $(this);
  var attributeHref = 'href'; // avoid tr ('*') scan
  var href = $link.attr(attributeHref);
  if (!href) {
    throw new Error('The "more" link has no href');
  }

  var identifier = $link.data('pagination-more-link');
  if (!identifier) {
    throw new Error('The "more" link has no identifier');
  }
  if (disabledLinks[identifier]) {
    return; // on a déjà cliqué, ça charge !
  }

  var $list = $('[data-pagination-more-list=' + identifier + ']');
  if ($list.length === 0) {
    throw new Error('Unable to find list "' + identifier + '"');
  }
  if ($list.length > 1) {
    throw new Error('Expected to find 1 list with name "' + identifier + '", but found "' + $list.length + '"');
  }

  // On lance le chargement de la page suivante
  console.log('Loading more content from', href, '...');
  disabledLinks[identifier] = true;
  $link.addClass('disabled');
  $.get(href, onLoaded);

  function onLoaded(html) {
    // On récupère les nouveautés de la page chargée
    var $newDocument = $(html);
    var $newListChilds = $newDocument.find('[data-pagination-more-list=' + identifier + '] > *');
    var $newLink = $newDocument.find('[data-pagination-more-link=' + identifier + ']');

    // On injecte le nouveau contenu
    $list.append($newListChilds);
    console.log('New content injected');

    // On modifie le bouton "More"
    if ($newLink.length === 0) {
      // Pas de bouton more, on est à la fin de la pagination
      $link.remove();
    } else {
      $link.attr(attributeHref, $newLink.attr(attributeHref));
      $link.removeClass('disabled');
    }

    delete disabledLinks[identifier]; // on a chargé, le bouton redevient actif
  }

  return false;
}
