/* global jQuery */
/* eslint wrap-iife: 0 */
// Preview for image file upload

(function($) {
  $.fn.filePreviewCtrl = function() {
    var $el = $(this);
    var $elParent = $el.parent();

    var $previewThumb = $('[data-pf-thumb]', $el);
    var $previewLabel = $('[data-pf-label]', $el);
    var $previewInput = $('[data-pf-input]', $el);
    var $previewDelete = $('[data-pf-delete]', $el);
    var $previewError = $('[data-pf-error]', $elParent);
    var imageType = $el.data('pf');
    var file = {};
    var currentFile;

    // Events
    $previewInput.on('change', updatePreview);

    // ////////////////////////////////////////

    function updatePreview() {
      var reader = new FileReader();
      file.path = $previewInput.val();

      if (file.path !== '') {
        file.ext = file.path.substring(file.path.lastIndexOf('.') + 1).toLowerCase();
        file.size = this.files[0].size; // size un kb
      }

      if (file.path !== currentFile && file.path !== '') {
        $previewError.hide();
        $previewDelete.show();

        reader.onload = function(e) {
          if (validateFile()) {
            $previewThumb.attr('style', 'background-image: url(' + e.target.result + ')');
            $previewLabel.addClass('is-set');
            currentFile = file.path;
          } else {
            $previewDelete.hide();
            $previewError.show();
            $previewThumb.removeAttr('style');
            $previewLabel.removeClass('is-set');
            $previewInput.val('');
            currentFile = '';
          }
        };
        reader.readAsDataURL(this.files[0]);
      } else {
        $previewInput.val('');
      }
    }

    function validateFile() {
      // Check file size in kB
      if (imageType === 'avatar') {
        if (file.ext === 'jpg' || file.ext === 'jpeg' || file.ext === 'png' || file.ext !== 'gif') {
          if (file.size > 1048576) {
            return false;
          }
        } else {
          return false;
        }
      } else if (imageType === 'desc' || imageType === 'logo' || imageType === 'banner' || imageType === 'block') {
        if (file.ext === 'jpg' || file.ext === 'jpeg' || file.ext === 'png') {
          if (file.size > 4194304) {
            return false;
          }
        } else {
          return false;
        }
      } else {
        console.log('no type');
      }

      return true;
    }

    return this;
  };
})(jQuery);

$('[data-pf]').each(function() {
  $(this).filePreviewCtrl();
});
