$('[data-confirm-email]').each(confirmEmailCtrl);

function confirmEmailCtrl() {
  var $el = $(this);

  // Variables
  var $confirmMsg = $('[data-confirm-email-msg]', $el);
  var $confirmSent = $('[data-confirm-email-sent]', $el);
  var $link = $('[data-confirm-email-link]', $el);

  // Events
  $link.on('click', sendConfirmationEmail);

  activate();

  // ////////////////////////////////////////

  function activate() {
    var tmpLink = document.createElement('a');
    tmpLink.href = document.location.href;

    if (tmpLink.pathname.indexOf('finish-register') !== -1) {
      $el.hide();
    }
  }

  function sendConfirmationEmail(e) {
    e.preventDefault();

    var tmpLink = document.createElement('a');
    tmpLink.href = document.location.href;
    var builtUrl = tmpLink.protocol + '//' + tmpLink.hostname + ':' + tmpLink.port + '/send-confirm-email';

    $.post(builtUrl)
      .success(function(res) {
        if (res.redirectTo) {
          location.href = res.redirectTo;
        } else {
          $confirmMsg.hide();
          $link.hide();
          $confirmSent.show();
        }
      })
      .error(function(res) {
        if (res.status === 418) {
          $('[data-login-error]').html(res.responseJSON.message);
        } else {
          console.log(res.status);
        }
      });
  }
}
