module.exports = function(params) {
  if (!params.link) {
    throw new Error('Missing params link');
  }
  if (params.id && (params.id === '5a063236807b5500046ab9ee')) {
    return {
      subject: `Hackathon.com - Email validation`,
      message: `Welcome to the <a href="http://www.hackathon.com/">hackathon.com</a> virtual hackathon portal!\n
        Here you can create and join hundreds of hackathons around the world, happening both locally and online.\n
        By participating in this hackathon, you accept the Terms & Conditions found here: http://ow.ly/c1fE30gPz4G\n
        To complete your registration, please click the link below.
        ${params.link}`
    };
  } else {
    return {
      subject: `Hackathon.com - Email validation`,
      message: `Welcome to the <a href="http://www.hackathon.com/">hackathon.com</a> virtual hackathon portal!\n
        Here you can create and join hundreds of hackathons around the world, happening both locally and online.\n
        To complete your registration, please click the link below.
        ${params.link}`
    };
  }
};
