/**
 * Scroll To Controller
 * @param {string} target - value optional - target element, if not defined target is the element itself
 * @param {number} shift - optional - shift value in pixel
 * @example '<a href="#" data-scroll-to data-scroll-shift="100"></a>'
 * @example '<a href="#" data-scroll-to="body"></a>'
 */

$('[data-scroll-to]').each(scrollToCtrl);

function scrollToCtrl() {
  var $el = $(this);

  var target = $el.data('scroll-to');
  var shift = $el.data('scroll-shift');
  var $body = $('body, html');

  // Events
  $el.on('click', scrollTo);

  // ////////////////////////////////////////

  function scrollTo(e) {
    e.preventDefault();

    let offsetEl;

    if (target !== undefined) {
      offsetEl = $(target).offset();
    } else {
      offsetEl = $el.offset();
    }

    if (shift === undefined) {
      shift = 0;
    }

    $body.animate({
      scrollTop: offsetEl.top + shift
    }, 800);
  }
}
