var delay = (function() {
  var timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

$(document).on('keyup', '[data-search-engine]', function(e) {
  var key = e.keyCode;
  var input = $(this);
  var postUrl = input.attr('data-result-url');
  var value = input.val();

  if (key !== 40 && key !== 38 && key !== 13) {
    delay(function() {
      $.post(postUrl, {search: value}, onSuccess);

      function onSuccess(data) {
        $('[data-search-engine-result]').html(data);
        searchEngineCtrl();
      }
    }, 300);
  }
});

// Hide the results if input is not focused
$('[data-search-engine]').each(searchEngineCtrl);

function searchEngineCtrl() {
  var $el = $(this);

  var screenWidth = window.innerWidth;
  var $resultContainer = $('[data-search-engine-result=desktop]');
  var $searchContainer = $('.ht-navbar__search');
  var $listItems = $resultContainer.find('.search-engine__link');

  // Events
  $el.focusin(showResults);

  if (screenWidth >= 1024) {
    // This line is made to hide the search menu when user click outside it
    $(document).on('click', hideResults);

    // Use key up and down to select result
    $el.on('keydown', selectResult);
  }

  // ////////////////////////////////////////

  function showResults() {
    $resultContainer.show();
  }

  function hideResults(e) {
    if (!$(e.target).closest($searchContainer).length) {
      if ($resultContainer.css('display') === 'block') {
        $resultContainer.hide();
      }
    }
  }

  function selectResult(e) {
    var key = e.keyCode;
    var $selected = $listItems.filter('.selected');
    var $current;

    if ( key !== 40 && key !== 38 && key !== 13) return;
    $listItems.removeClass('selected');

    if (key === 40 || key === 38) {
      if (key === 40) { // Down key
        if (!$selected.length || !$selected.nextAll('.search-engine__link').length) {
          $current = $listItems.eq(0);
        } else {
          $current = $selected.nextAll('.search-engine__link').first();
        }
      } else if (key === 38) { // Up key
        if (!$selected.length || !$selected.prevAll('.search-engine__link').length) {
          $current = $listItems.last();
        } else {
          $current = $selected.prevAll('.search-engine__link').first();
        }
      }
      $current.addClass('selected');
    } else if (key === 13 && $selected.length) {
      $selected[0].click();
      return false;
    }
  }
}
