$('[data-flash-msg]').each(flashMsgCtrl);

function flashMsgCtrl() {
  var $el = $(this);

  // Variables
  var $closeBtn = $('[data-flash-msg-close]', $el);

  // Events
  $closeBtn.on('click', closeFlashMsg);

  // ////////////////////////////////////////

  function closeFlashMsg(e) {
    e.preventDefault();
    $el.remove();
  }
}
