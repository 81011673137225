module.exports = function(params) {
  if (!params.reset) {
    throw new Error('Missing params reset');
  }
  if (!params.account) {
    throw new Error('Missing params account');
  }
  return {
    subject: `Recover password`,
    message: `Hi ${params.account.username || ''},\n
      We\'ve received a request to reset your password.
      If you didn\'t make the request, just ignore this email.\n
      Otherwise, you can reset your password using this link:
      ${params.reset}`
  };
};
