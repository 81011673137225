$('[data-topic-list]').each(formTopicsCtrl);

function formTopicsCtrl() {
  var $el = $(this);

  // variables
  var selectionLimit = 4;
  var selectedTopics = 0;
  var $counter = $('[data-topic-counter]');
  var $checkbox = $('.topic input[type=checkbox]');

  // Events
  $checkbox.on('change', isLimitReached);
  activate();

  // ////////////////////////////////////////

  function activate() {
    $checkbox.each(function() {
      initTopics($(this));
    });
  }
  function isLimitReached() {
    if ($(this).prop('checked')) {
      if (selectedTopics < selectionLimit) {
        selectedTopics++;
        $counter.text(selectedTopics);
      }

      if (selectedTopics === selectionLimit) {
        $checkbox.each(function() {
          if (!$(this).prop('checked')) {
            $(this).addClass('disabled');
          }
        });
      }
    } else {
      if (selectedTopics === selectionLimit) {
        $checkbox.each(function() {
          if (!$(this).prop('checked')) {
            $(this).removeClass('disabled');
          }
        });
      }
      selectedTopics--;
      $counter.text(selectedTopics);
    }
  }
  function initTopics(elm) {
    if ($(elm).prop('checked')) {
      if (selectedTopics < selectionLimit) {
        selectedTopics++;
        $counter.text(selectedTopics);
      }

      if (selectedTopics === selectionLimit) {
        $checkbox.each(function() {
          if (!$(this).prop('checked')) {
            $(this).addClass('disabled');
          }
        });
      }
    }
  }
}
