/* global forcedRedirectionUrl */

$('[data-login]').on('submit', function(event) {
  event.preventDefault();

  if (global.forcedRedirectionUrl) {
    $(this).find('[name=redirectionUrl]').val(global.forcedRedirectionUrl);
  }

  $.post($(this).attr('action'), $(this).serialize())
    .success(function(res) {
      if (res.redirectTo) {
        location.href = res.redirectTo;
      } else if (global.forcedRedirectionUrl) {
        // redirection for downloding file
        if (global.forcedRedirectionUrl.indexOf('/record.mp4') !== -1) {
          $('#sign-popup').foundation('close');
          location.href = global.forcedRedirectionUrl;
          location.reload();
        } else {
          const params = JSON.parse($('#param').attr('data-parameters'));
          if (params.accountForceEmailValidation && !params.isEmailConfirmed) {
            location.href = global.forcedRedirectionUrl.substring(0, global.forcedRedirectionUrl.indexOf('#/')) + '#/confirm-email';
          } else {
            location.href = global.forcedRedirectionUrl;
          }
        }
      } else {
        location.reload();
      }
    })
    .error(function(res) {
      if (res.status === 418) {
        $('[data-login-error]').html(res.responseJSON.message);
        $('[data-button-loader]').removeClass('active');
      }
    });
});

$('body').on('click', '[data-resend-validation]', function(event) {
  event.preventDefault();
  $.get($(this).attr('href'))
    .success(function(res) {
      $('[data-sign-form], [data-register-send]').toggleClass('hide');
    })
    .error(function(res) {
      if (res.status === 418) {
        $('[data-login-error]').html(res.responseJSON.message);
      }
    });
});
