$('[data-button-loader]').each(buttonLoaderCtrl);

function buttonLoaderCtrl() {
  var $el = $(this);

  // Variables
  var buttonContent = $el.html();

  // Events
  $(document).ready(addLoader);
  $el.on('click', activeLoader);

  // ////////////////////////////////////////

  function addLoader() {
    var loader = '<span class="loader"><span class="loader__point loader__point--one"></span>' +
      '<span class="loader__point loader__point--two"></span>' +
      '<span class="loader__point loader__point--three"></span></span>';

    $el.addClass('button-loader');
    $el.html(buttonContent + loader);
  }

  function activeLoader() {
    $el.addClass('active');
  }
}
