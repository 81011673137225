// Forgot Password
$('[data-forgot]').each(forgotPasswordCtrl);

function forgotPasswordCtrl() {
  var $el = $(this);

  var $forgotContainer = $('[data-forgot-container]');
  var $loginContainer = $('[data-login-container]');
  var $loginEmailInput = $('[data-login-email]');
  var $forgotEmailInput = $('[data-forgot-email]');
  var $showLink = $('[data-show-forgot]');
  var $hideLink = $('[data-hide-forgot]');

  // Events
  $showLink.on('click', showForgot);
  $hideLink.on('click', hideForgot);
  $loginEmailInput.on('keyup', duplicateEmail);
  $el.on('submit', sendForm);

  // ////////////////////////////////////////

  function showForgot(event) {
    event.preventDefault();
    $loginContainer.hide();
    $forgotContainer.show();
  }

  function hideForgot(event) {
    event.preventDefault();
    $loginContainer.show();
    $forgotContainer.hide();
  }

  function duplicateEmail() {
    var email = $loginEmailInput.val();
    $forgotEmailInput.val(email);
  }

  function sendForm(event) {
    event.preventDefault();
    $.post($(this).attr('action'), $(this).serialize())
      .success(function(res) {
        $('[data-button-loader]').removeClass('active');
        $('[data-sign-form], [data-reset-send]').toggleClass('hide');
      })
      .error(function(res) {
        $('[data-button-loader]').removeClass('active');
        if (res.status === 418) {
          $('[data-forgot-error]').html(res.responseJSON.message);
        } else if (res.status === 404) {
          // If unknown email, act as if to avoid fishing
          $('[data-sign-form], [data-reset-send]').toggleClass('hide');
        }
      });
  }
}
