// Reset Password
$('[data-reset]').each(resetPasswordCtrl);

function resetPasswordCtrl() {
  const $el = $(this);

  let valFirstInput;
  let valLastInput;

  const $firstInput = $('[data-reset-one]', $el);
  const $lastInput = $('[data-reset-two]', $el);

  const $firstInputErr = $('[data-login-error]');
  const $lastInputErr = $('[data-login-error-2]');

  const $redirectUrl = $('[name="redirectionUrl"]').val();

  // Events
  $el.on('submit', sendForm);
  $firstInput.on('keyup', removeError);
  $lastInput.on('keyup', removeError);

  // ////////////////////////////////////////

  function removeError(e) {
    if (e.which === 13) return;
    $firstInput.removeClass('error');
    $firstInputErr.html('');
    $lastInput.removeClass('error');
    $lastInputErr.html('');
  }

  function toggleError(input1, input1Err, input2, input2Err, msg) {
    input1.removeClass('error');
    input1Err.html('');

    input2.addClass('error');
    input2Err.html(msg);
  }

  function checkPassword() {
    valFirstInput = $firstInput.val();
    valLastInput = $lastInput.val();

    if (valFirstInput.length === 0) {
      toggleError($lastInput, $lastInputErr, $firstInput, $firstInputErr, 'Password is required');
      return false;
    }
    if (valFirstInput.length > 0 && valLastInput.length === 0) {
      toggleError($firstInput, $firstInputErr, $lastInput, $lastInputErr, 'Passwords must match');
      return false;
    }
    if (valLastInput.length > 0 && valFirstInput.length > 0 && valFirstInput !== valLastInput) {
      toggleError($firstInput, $firstInputErr, $lastInput, $lastInputErr, 'Passwords must match');
      return false;
    }
    $firstInput.removeClass('error');
    $firstInputErr.html('');
    $lastInput.removeClass('error');
    $lastInputErr.html('');
    return true;
  }

  function sendForm(e) {
    e.preventDefault();

    if (checkPassword() === true) {
      $.post($(this)
        .attr('action'), $(this)
        .serialize())
        .success(function (res) {
          window.location.href = $redirectUrl;
        })
        .error(function (res) {
        });
    }
    return false;
  }
}
