/* global jQuery */

// switchCtrl is a jQuery chainable function
(function( $ ) {
  $.fn.switchCtrl = function() {
    var $el = $(this);

    // variables
    var $checkboxSwitch = $('[data-switch-checkbox]', this);
    var $innerSwitch = $('[data-switch-inner]', this);
    var $btnSwitch = $('[data-switch-btn]', this);
    var labelOn = $('.switch-label--on', this).text();
    var labelOff = $('.switch-label--off', this).text();
    var hasExt;
    var $extOn = $el.siblings('[data-switch-ext-on]');
    var $extOff = $el.siblings('[data-switch-ext-off]');

    // Events
    activate();
    $el.on('click', toggleSwitch);

    // ////////////////////////////////////////

    function activate() {
      fitLabel();
      if ($el.hasClass('has-ext')) {
        hasExt = true;
      }

      if ($checkboxSwitch.attr('checked') !== undefined) {
        $innerSwitch.addClass('on');
        $btnSwitch.addClass('on');

        if (hasExt === true && $extOn !== undefined && $extOff !== undefined) {
          $extOn.show();
        }
      } else {
        $innerSwitch.addClass('off');
        $btnSwitch.addClass('off');

        if (hasExt === true && $extOn !== undefined && $extOff !== undefined) {
          $extOff.show();
        }
      }
    }

    function toggleSwitch() {
      var checkboxStatus = $checkboxSwitch.attr('checked');

      if (checkboxStatus !== undefined) {
        $checkboxSwitch.removeAttr('checked');
        $checkboxSwitch.attr('value', 'false');
        $innerSwitch.removeClass('on').addClass('off');
        $btnSwitch.removeClass('on').addClass('off');

        if (hasExt === true && $extOn !== undefined && $extOff !== undefined) {
          $extOn.hide();
          $extOff.show();
        }
      } else {
        $checkboxSwitch.attr({'checked': 'checked', 'value': 'true'});
        $innerSwitch.removeClass('off').addClass('on');
        $btnSwitch.removeClass('off').addClass('on');

        if (hasExt === true && $extOn !== undefined && $extOff !== undefined) {
          $extOff.hide();
          $extOn.show();
        }
      }
    }

    function fitLabel() {
      var labelWidth = Math.max(labelOn.length, labelOff.length);

      if (labelWidth > 3) {
        var totalWidth = (labelWidth * 3) + 80;

        $el.attr('style', 'width: ' + totalWidth + 'px;');
      }
    }

    return this;
  };
})( jQuery );

$('[data-switch]').each(function() {
  $(this).switchCtrl();
});
