$('.ht-footer').each(footerCtrl);

function footerCtrl() {
  var $el = $(this);

  // Variables
  var bodyHeight = $('body').height();
  var footerHeight = 120;
  var innerHeight = window.innerHeight;
  var contentHeight = innerHeight - footerHeight;

  // Events
  $(document).ready(getSticky);

  // ////////////////////////////////////////

  function getSticky() {
    if (bodyHeight < contentHeight) {
      $el.addClass('sticky');
    }
  }
}
