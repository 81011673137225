/* globals google */

// Type selection
$('[data-select-type]').each(selectTypeCtrl);

function selectTypeCtrl() {
  var $el = $(this);

  var $formSelection = $('#selectType');
  var selectedValue = $el.data('select-type');
  var $inputTarget = $('[data-select-input]');

  // Events
  $el.on('click', goNextStep);

  // ////////////////////////////////////////

  function goNextStep(e) {
    e.preventDefault();

    $inputTarget.val(selectedValue);
    $formSelection.submit();
  }
}

// Hide or Show location details inputs
$('[data-event-location]').each(locationCtrl);

function locationCtrl() {
  var $el = $(this);

  // Variables
  var $inputs = $('[data-event-local-input]');

  // Events
  $el.on('change', toggleLocationInputs);

  // ////////////////////////////////////////

  function toggleLocationInputs() {
    var selectVal = $(this).val();

    if (selectVal === 'local') {
      $inputs.show();
    } else {
      $inputs.hide();
    }
  }
}

// Link all picker for event creation
var nowTemp = new Date();
var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
var nextYear = nowTemp.setFullYear(nowTemp.getFullYear() + 1);
var endDate = new Date(nextYear);
var parsedEndDate = endDate.toISOString().slice(0, 10);

// Start date selection
$('#startDate').bootstrapMaterialDatePicker({
  date: true,
  time: false,
  shortTime: true,
  currentDate: now,
  format: 'MM/DD/YYYY',
  clearButton: false
}).on('change', function(e, date) {
  $('#endDate').bootstrapMaterialDatePicker('setMinDate', date);
});

// End date selection
$('#endDate').bootstrapMaterialDatePicker({
  date: true,
  time: false,
  shortTime: true,
  currentDate: now,
  format: 'MM/DD/YYYY',
  clearButton: false
}).on('change', function(e, date) {
  $('#startDate').bootstrapMaterialDatePicker('setMaxDate', date);
});
enableTimeInput($('#startTime'));
enableTimeInput($('#endTime'));


function enableTimeInput(element) {
  window.element = element;
  var value = element.val();
  if (!value) {
    var date = moment();
    var datestring = date.format('hh:mm A');
    element.val(datestring);
  }

  var currentSelected = 0;

  element.bind('click', function(event) {
    if (this.selectionStart < 3) {
      currentSelected = 0;
    } else if (this.selectionStart < 5) {
      currentSelected = 1;
    } else {
      currentSelected = 2;
    }
    updateSelectedArea();
  });

  element.bind('focus', function(event) {
    currentSelected = 0;
    updateSelectedArea();
  });

  element.bind('keydown keypress', function(event) {
    if ((event.key === 'Tab' || event.key === 'ArrowRight' ) && currentSelected < 2) {
      currentSelected++;
      updateSelectedArea();
      event.preventDefault();
      return;
    } else if (event.key === 'ArrowLeft' && currentSelected > 0) {
      currentSelected--;
      updateSelectedArea();
      event.preventDefault();
      return;
    } else if (event.key === 'Tab' ) {
      return;
    }

    var viewValue = element.val();
    var key = event.key;
    var hour = viewValue.substring(0, 2);
    var minute = viewValue.substring(3, 5);
    var part = viewValue.substring(6, 8);

    if (event.key === 'ArrowUp') {
      if (currentSelected === 0) {
        hour = parseInt(hour, 10);
        hour = Math.min(12, ++hour);
        hour = hour > 9 ? hour : '0' + hour;
      } else if (currentSelected === 1) {
        minute = parseInt(minute, 10);
        minute = Math.min(59, ++minute);
        minute = minute > 9 ? minute : '0' + minute;
      } else if (currentSelected === 2) {
        part = (part === 'PM' ? 'AM' : 'PM');
      }
    } else if (event.key === 'ArrowDown') {
      if (currentSelected === 0) {
        hour = parseInt(hour, 10);
        hour = Math.max(0, --hour);
        hour = hour > 9 ? hour : '0' + hour;
      } else if (currentSelected === 1) {
        minute = parseInt(minute, 10);
        minute = Math.max(0, --minute);
        minute = minute > 9 ? minute : '0' + minute;
      } else if (currentSelected === 2) {
        part = (part === 'PM' ? 'AM' : 'PM');
      }
    }

    if (/[0-9]/.test(key) ) {
      if (currentSelected === 0) {
        if (hour === '00') {
          hour = '0' + key;
        } else if (hour === '01') {
          hour = '1' + (parseInt(key, 10) < 3 ? key : '2');
        } else {
          hour = '0' + key;
        }
      } else if (currentSelected === 1) {
        if (minute === '00') {
          minute = '0' + key;
        } else if (/[0][0-5]/.test(minute)) {
          minute = minute.substring(1, 2) + key;
        } else {
          minute = '0' + key;
        }
      }
    } else if (/A|P|a|p/.test(key)) {
      if (key === 'A' || key === 'a') {
        part = 'AM';
      } else if (key === 'P' || key === 'p') {
        part = 'PM';
      }
    }

    viewValue = hour + ':' + minute + ' ' + part;
    element.val(viewValue);
    event.preventDefault();
    updateSelectedArea();
  });

  function updateSelectedArea() {
    if (currentSelected === 0) {
      element[0].setSelectionRange(0, 2);
    } else if (currentSelected === 1) {
      element[0].setSelectionRange(3, 5);
    } else if (currentSelected === 2) {
      element[0].setSelectionRange(6, 8);
    } else {
      element[0].setSelectionRange(0, 0);
    }
  }
}


var autocomplete;
var mustChoose = false;
$(function() {
  var autocompleteSelector = document.querySelector('[name=venueLocation]');

  if (autocompleteSelector) {
    autocomplete = new google.maps.places.Autocomplete(
        autocompleteSelector,
        {types: ['geocode']}
      );

    autocomplete.addListener('place_changed', fillInAddress);

    autocompleteSelector.addEventListener('keydown', function() {
      $('[name=city]').val('');
      $('[name=country]').val('');
      $('[name=venueAddress]').val('');
      $('[name=geo]').val('');
    });
  }
});

function fillInAddress() {
  var place = autocomplete.getPlace();

  $('[name=city]').val('');
  $('[name=country]').val('');
  place.address_components.forEach(function(ac) {
    if (ac.types.indexOf('locality') !== -1) {
      $('[name=city]').val(ac.long_name);
    } else if (ac.types.indexOf('country') !== -1) {
      $('[name=country]').val(ac.long_name);
    }
  });
  $('[name=venueAddress]').val(place.name);
  $('[name=geo]').val('[' + place.geometry.location.lng() + ',' + place.geometry.location.lat() + ']');

  if ($('[name=city]').val() === '') {
    $('[name=venueLocation]').addClass('error');
    $('.hidden_error').css('display', 'inline');
  } else {
    $('[name=venueLocation]').removeClass('error');
    $('.hidden_error').css('display', '');
    $('.error__ms').hide();
  }

  mustChoose = false;
}
