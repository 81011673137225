/* globals isLogged */

// Cette variable sera utilisée lors du login ou signup pour changer l'url de post-login/signup.
// Pour le moment, son settage se fait uniquement au clique sur Create Hackathon en étant
// déloggé, mais n'importe quel code javascript peut la modifier.

var redirect = getParameterByName('redirect');
// var forcedRedirectionUrl;
if (redirect) {
  global.forcedRedirectionUrl = redirect;
}

$('[data-force-redirect]').on('click', function() {
  global.forcedRedirectionUrl = $(this).data('forceRedirect');
});

function getParameterByName(name) {
  var url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

if (getParameterByName('login')) {
  if (!preventModalOpening()) {
    $('#sign-popup').foundation('open');
    $('.sign__tabs').foundation('selectTab', 'sign-in', true);
  }
}

if (getParameterByName('signup')) {
  if (!preventModalOpening()) {
    $('#sign-popup').foundation('open');
    $('.sign__tabs').foundation('selectTab', 'sign-up', true);
  }
}

// Controlling sign-popup opening
$('[data-open-sign-popup]').each(signPopupCtrl);

function signPopupCtrl() {
  var $el = $(this);

  var targetTab = $el.data('open-sign-popup');
  var targetModal = $('#sign-popup');
  var $tab = $('.sign__tabs');

  // Events
  $el.on('click', openModal);

  // ////////////////////////////////////////

  function openModal(e) {
    if (preventModalOpening()) {
      return;
    }
    e.preventDefault();

    targetModal.foundation('open');
    $tab.foundation('selectTab', targetTab, true);
  }
}

// Put back at initial sate when closing the modal
$('#sign-popup').each(closingModalCtrl);

function closingModalCtrl() {
  var $el = $(this);

  var $formContainer = $('[data-sign-form]', $el);
  var $msgContainer = $('.sign__send-ms', $el);
  var $loginContainer = $('[data-login-container]', $el);
  var $forgotContainer = $('[data-forgot-container]', $el);

  // Events
  $el.on('closed.zf.reveal', hideMsgContainer);

  // ////////////////////////////////////////

  function hideMsgContainer() {
    $formContainer.removeClass('hide');
    $msgContainer.addClass('hide');
    $loginContainer.show();
    $forgotContainer.hide();
  }
}

function preventModalOpening() {
  // If the user is already logged, this popup must'nt be shown
  // and if we have a redirection information, we redirect
  if (isLogged) {
    if (redirect) {
      location.href = redirect;
    }
    return true;
  }
  return false;
}
