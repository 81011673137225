$('[confirm-password],[new-password]').on('keyup', function() {
  if ($('[confirm-password]').val() !== $('[new-password]').val()) {
    $('[confirm-password]').parent().addClass('error');
    $('[confirm-password]').parent().find('.error-msg').html('Password mismatch');
  } else {
    $('[confirm-password]').parent().removeClass('error');
    $('[confirm-password]').parent().find('.error-msg').html('');
  }
});

// avoid submit when google selector is focus.
$('[profile-form]').on('submit', function(e) {
  if ($('[data-localisation]').is(':focus')) {
    e.preventDefault();
    return false;
  }
});

$('[send-profile]').on('click', function() {
  $('[profile-form]').submit();
});

window.onload = function() {
  if ($('.error-pwd')[0] && $('.error-pwd')[0].textContent) {
    var x = $('.error-pwd').position();
    window.scrollTo(x.left, x.top);
  }
};

window.cancelRemoveUser = function cancelRemoveUser() {
  $('#modal-remove-user').hide();
};

window.validRemoveUser = function validRemoveUser() {
  $('#modal-remove-user').hide();
  $.post('/delete-me', function(res) {
    // show notifaction
    var email = $('input[type=text][name=email]').val();
    var mgs = '<div class="flash-msg flash-msg--success">' +
    '<div class="flash-msg__icon"><svg class="ideacon idea-ht-circle-checked" ng-class="{open : showTools}">' +
    '<use xlink:href="icons/ideacons-defs.svg#idea-ht-circle-checked"></use></svg></div>' +
    '<div class="flash-msg__content">The account ' + email + ' is deleted</div>' +
    '</div>';
    $('.flash-msg__container').append(mgs);
    setTimeout(function() {
      if (location.pathname.indexOf('hackathon') === -1) { // on the prod
        location.href = '/';
      } else { // on an PR
        location.href = '/hackathon';
      }
    }, 2000);
  });
};

window.removeUser = function removeUser() {
  $('#modal-remove-user').show();
};
