module.exports = function(params) {
  if (params.confirmEmail === undefined) {
    params.confirmEmail = '';
  }
  return {
    subject: 'Welcome on Hackathon.com',
    message: `Congratulations, you have joined the Hackathon.com community.\n
      Here you can join hundreds of hackathons around the world or create your own event.\n
      Have we got the right email address to reach you on? To confirm that you can get our emails, just click on the link below.\n
      ${params.confirmEmail}
      Thanks and welcome !\n\n
      The Hackathon.com team`
  };
};
