/* globals google ga */

// Foundation initialisation
$(document).foundation();

// Highlights
$('[data-hl-hero]').each(heroHighlightCtrl);

function heroHighlightCtrl() {
  const $el = $(this);

  // Variables
  const $highlightsBg = $('.ht-highlights__background');
  const $highlightsItems = $('[data-hl-hero]');
  const heroId = $el.data('hl-hero');
  const $highlightsHeroes = $('[data-hl-id]');

  // Events
  $el.on('click', showHighlightHero);
  $('.hl-list__item__tag', $el).on('click', stopEvent);

  // ////////////////////////////////////////

  function showHighlightHero() {
    let newBg;
    if ($(`[data-hl-id=${heroId}] .hero__banner`).data('hl-has-banner')) {
      const imgLink = $(`[data-hl-id=${heroId}] .hero__banner__img`).attr('src');
      newBg = `url("${imgLink}")`;
    } else {
      newBg = $(`[data-hl-id=${heroId}] .hero__logo`).css('background-image');
    }

    $highlightsBg.css('background-image', newBg);
    $highlightsHeroes.addClass('hide');
    $(`[data-hl-id=${heroId}]`).removeClass('hide');
    $highlightsItems.removeClass('hide');
    $(this).addClass('hide');
  }

  function stopEvent(e) {
    e.stopPropagation();
  }
}

let countryCode;
let city;
let country;

$(function() {
  $('.ht-newsletter__form').submit(function(e) {
    e.preventDefault();
    const form = $(this);
    const url = form.attr('action');

    const email = form.find('[type=email]').val();
    $.post(url, {
      email: email,
      countryCode: countryCode
    }, function(data) {
      $('.ht-newsletter__form').hide();
      $('.ht-newsletter__message').show();
    })
      .fail(function() {
        $('.ht-newsletter__form-email').addClass('error');
      });
    return false;
  });
});

// for all form with autocomplete
getCurrentLocalisation(function(localCity, localCountry, localCountryCode) {
  checkGoogleAPIStatus(function(enable) {
    $('[data-localisation-autocomplete]').each(function(index, dom) {
      enableAutocomplete(dom, localCity, localCountry, localCountryCode, true, enable);
    });
    $('[data-localisation-semiautocomplete]').each(function(index, dom) {
      enableAutocomplete(dom, localCity, localCountry, localCountryCode, false, enable);
    });
  });
});

function getCurrentLocalisation(cb) {
  if (city && country) { // already called;
    return cb(city, country, countryCode);
  }
  $.get('https://geoip-db.com/json/', function(res) {
    res = JSON.parse(res);
    if (res.country_name && res.city) {
      countryCode = res.country_code;
      city = res.city;
      country = res.country_name;
      cb(city, country, countryCode);
    } else if (res.latitude && res.longitude) {
      $.get('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBHDMxBXdfAoTSNzoTjQqzIMLSInst0Nr0&latlng=' +
        res.latitude + ',' + res.longitude, function(res2) {
        if (res2.results.length) {
          res2.results[0].address_components.forEach(function(ac) {
            if (ac.types.indexOf('locality') !== -1) {
              city = ac.long_name;
            } else if (ac.types.indexOf('country') !== -1) {
              country = ac.long_name;
              countryCode = ac.short_name;
            }
          });
        }
        cb(city, country, countryCode);
      });
    }
  });
}

// https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
function checkGoogleAPIStatus(callback) {
  function displaySuggestions(predictions, status) {
    const enable = !(status === google.maps.places.PlacesServiceStatus.INVALID_REQUEST ||
    status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT ||
    status === google.maps.places.PlacesServiceStatus.REQUEST_DENIED ||
    status === google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR);
    callback(enable);
  }
  const autocompleteService = new google.maps.places.AutocompleteService({ types: ['(cities)'] });
  autocompleteService.getQueryPredictions({ input: 'Nantes' }, displaySuggestions);
}

function enableAutocomplete(domForm, defaultCity, defaultCountry, defaultCountryCode, autofilling, enable) {
  const form = $(domForm);

  if (!form.find('[data-localisation]')[0]) return;

  const accountAutocomplete = new google.maps.places.Autocomplete(
    form.find('[data-localisation]')[0],
    { types: ['(cities)'] }
  );

  accountAutocomplete.addListener('place_changed', fillInHomeAddress);

  function fillInHomeAddress() {
    const place = accountAutocomplete.getPlace();
    place.address_components.forEach(function(ac) {
      if (ac.types.indexOf('locality') !== -1) {
        city = ac.long_name;
      } else if (ac.types.indexOf('country') !== -1) {
        country = ac.long_name;
        countryCode = ac.short_name;
      }
    });
    setLocalisationValues(city, country, countryCode);
  }

  if (autofilling) {
    defaultCity = form.find('[data-city]').val() || defaultCity; // auto detected of bdd value
    defaultCountry = form.find('[data-country]').val() || defaultCountry; // auto detected of bdd value
    setLocalisationValues(defaultCity, defaultCountry, defaultCountryCode);
  } else {
    defaultCity = form.find('[data-city]').val(); // bdd value
    defaultCountry = form.find('[data-country]').val(); // bdd value
    setLocalisationValues(defaultCity, defaultCountry, defaultCountryCode);
  }

  function setLocalisationValues(cityValue, countryValue, countryCodeValue) {
    const cityHeader = document.querySelector('[data-city-header]');
    const countryHeader = document.querySelector('[data-country-header]');
    if (cityHeader) {
      cityHeader.textContent = cityHeader.textContent || cityValue;
      countryHeader.textContent = countryHeader.textContent || countryValue;
    }
    form.find('[data-city]').val(cityValue);
    form.find('[data-country]').val(countryValue);
    form.find('[data-countryCode]').val(countryCodeValue);
    if (form.find('[data-citylink]')[0]) {
      form.find('[data-citylink]').attr('href', form.find('[data-citylink]').attr('base-url')
        .replace('placeholder_country', countryValue)
        .replace('placeholder_city', cityValue));
    }
    if (cityValue && countryValue) {
      form.find('[data-localisation]').val(cityValue + ', ' + countryValue);
    } else if (countryValue) {
      form.find('[data-localisation]').val(countryValue);
    } else {
      form.find('[data-localisation]').val('');
    }
  }
  // User must use google autocomplete
  form.find('[data-localisation]').on('input', function(e) {
    form.find('[data-city]').val('');
    form.find('[data-country]').val('');
    city = '';
    country = '';
  });

  if (!enable) {
    if (form.find('[data-localisation]')[0].name === 'localisation') {
      form.find('[data-localisation]').hide();
      form.find('[data-localisation-label]').hide();
    } else {
      form.find('[data-localisation]').after('<p class="error__ms">A problem occured with the city form ' +
      'powered by Google can you check your connection or your proxy and reload the page ?</p>');
    }
  }
}

function handleCorporateLinks() {
  const links = [
    {
      cssClass: 'a.ht-menu__coporate-link',
      source: 'Hackathon.com',
      medium: 'Header'
    },
    {
      cssClass: 'a.ht-menu__masterclass-link',
      source: 'Hackathon.com',
      medium: 'Header'
    },
    {
      cssClass: 'a.ht-corporate__button',
      source: 'Hackathon.com',
      medium: 'Home'
    },
    {
      cssClass: 'a.ht-corporate__button-create',
      source: 'Hackathon.com',
      medium: 'Home'
    }
  ];

  const todayId = randomId();

  let campaign = getParameterByName('utm_campaign') || document.referrer;

  if (campaign.indexOf(window.location.hostname) !== -1 || !campaign) {
    campaign = getCookie('referrer');
  }

  if (!campaign) {
    campaign = 'direct';
  } else {
    setCookie('referrer', campaign);
  }

  $('body').on('click', links.map(function(l) { return l.cssClass; }).join(', '), function(event) {
    ga('send', 'event', {
      eventCategory: 'corporate',
      eventAction: 'click',
      eventLabel: todayId
    });
  });

  links.forEach(function(link, index) {
    const elem = $(link.cssClass);
    const currentHref = elem.attr('href');
    const newHref = currentHref + '?' +
      'utm_source=' + link.source +
      '&utm_medium=' + link.medium +
      '&utm_campaign=' + campaign +
      '&utm_content=' + todayId;

    elem.attr('href', newHref);
  });
}

function randomId() {
  return Array(10)
    .join((Math.random().toString(36) + '00000000000000000').slice(2, 18))
    .slice(0, 9);
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

function setCookie(name, value) {
  document.cookie = name + '=' + value + ';expires=Wed, 18 Dec 2023 12:00:00 GMT';
}

handleCorporateLinks();
