$('[data-timer]').each(timerCtrl);

function timerCtrl() {
  var $el = $(this);

  var $finalDate = $el.data('timer-date');
  var $days = $('[data-timer-days]', $el);
  var $daysText = $('[data-timer-days-text]', $el);
  var $hours = $('[data-timer-hours]', $el);
  var $minutes = $('[data-timer-min]', $el);

  activate();

  // ////////////////////////////////////////

  function activate() {
    $el.countdown($finalDate, function(event) {
      $days.html(event.strftime('%D'));
      $hours.html(event.strftime('%H'));
      $minutes.html(event.strftime('%M'));

      if (event.strftime('%D') <= 1) {
        $daysText.empty().text('day');
      }
    });
  }
}
