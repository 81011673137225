$('[data-ht-toggle]').each(toggleCtrl);

function toggleCtrl() {
  var $el = $(this);

  var $dropdown = $el.next('[data-ht-dropdown]');

  // Events
  $el.on('click', toggle);

  // ////////////////////////////////////////

  function toggle(e) {
    e.preventDefault();

    $dropdown.toggle();
  }
}

$('.profile-header__avatar__delete').on('click', function() {
  $(this).hide();
  $('[name=deleteAvatar]').val('true');
  $('[name=avatar]').val('');
  $('.profile-header__avatar__preview').css('background-image', '');
});
